import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import Guide from "../../files/sample-size-guidelines.pdf"

export default function DownloadsDataAccess() {
  return (
    <section className="downloads">
      <div id="downloads" />
      <Container>
        {" "}
        <h2 className="mt-0 mb-5 text-center">
          <span className="sub-heading">DOWNLOADS</span>{" "}
          <span className="text-white">& DATA ACCESS</span>
        </h2>
        <Row className="justify-content-center">
          <Col lg="3" className="py-3">
            <a
              href="https://res.cloudinary.com/dwzjvi83z/raw/upload/v1619752490/bonafides/bflab_coc_jr6qng.xls"
              alt="Chain of Custody"
              download
            >
              <div className="dl-box text-center d-flex justify-content-center align-items-center">
                <div>
                  <StaticImage
                    src="../../images/icons/chain.svg"
                    alt="Chain of Custody"
                    width={80}
                  />

                  <h3 className="mt-3 mb-0">Chain of Custody</h3>
                </div>
              </div>
            </a>
          </Col>
          <Col lg="3" className="py-3">
            <a href={Guide} alt="Lab Testing Sample Size Guide" target="_blank">
              <div className="dl-box text-center d-flex justify-content-center align-items-center">
                <div>
                  <StaticImage
                    src="../../images/icons/lab.svg"
                    alt="Chain of Custody"
                    width={80}
                  />

                  <h3 className="mt-3 mb-0">Lab Testing Sample Size Guide</h3>
                </div>
              </div>
            </a>
          </Col>
          <Col lg="3" className="py-3">
            {" "}
            <a
              // href="https://data.bonafideslab.com/"
              href="http://data.bonafideslab.com"
              alt="Data Access"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="dl-box text-center d-flex justify-content-center align-items-center">
                <div>
                  <StaticImage
                    src="../../images/icons/data.svg"
                    alt="Chain of Custody"
                    width={80}
                  />

                  <h3 className="mt-3 mb-0">DATA ACCESS</h3>
                </div>
              </div>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
