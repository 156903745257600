import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Icon1 from "@images/icons/terpenes.svg"
import Icon2 from "@images/icons/pesticide.svg"
import Icon3 from "@images/icons/microbials.svg"
import Icon4 from "@images/icons/heavy-metals.svg"
import Icon5 from "@images/icons/residual-solvents.svg"
import Icon6 from "@images/icons/potency.svg"
import Icon7 from "@images/icons/mycotoxins.svg"
import Icon8 from "@images/icons/water.svg"

const services = [
  {
    class: "box1",
    icon: Icon1,
    name: "TERPENES – GC/MS",
    desc: "Quantitative results 23 terpenoids",
    desc2: "",
    link: "/services/terpene-testing",
  },
  {
    class: "box2",
    icon: Icon2,
    name: "PESTICIDES – LC/MS/MS",
    // desc: "Quantitative results for 31 terpenoids",
    // desc2: "Analysis of all 13 state specified pesticides",
    link: "/services/pesticides",
  },
  {
    class: "box3",
    icon: Icon3,
    name: "MICROBIALS AND CONTAMINANTS",
    desc: "Hop Latent Viroid, Gender ID, & Environmental Testing available",
    // desc2: "Hop Latent Viroid, Gender ID, & Environmental Testing available",
    link: "/services/microbials-and-contaminants",
  },
  {
    class: "box4",
    icon: Icon4,
    name: "HEAVY METALS - ICP/MS",
    desc: "Analysis of all 4 state specified heavy metals",
    // desc2: "ISO Accredited",
    link: "/services/heavy-metals",
  },
  {
    class: "box5",
    icon: Icon5,
    name: "RESIDUAL SOLVENTS - GC/MS",
    desc: "Analysis of all 13 state specified solvents",
    // desc2: "Full microbial panel includes Aspergillus",
    link: "/services/residual-solvents",
  },
  {
    class: "box6",
    icon: Icon6,
    name: "POTENCY – HPLC",
    desc: "CBC, CBD, CBDa, CBDV, CBG, CBGa, CBN, D-8THC, D9-THC, D10-THC, Exo-THC, THCa, THCV",
    // desc2: "Full microbial panel includes Aspergillus",
    link: "/services/potency",
  },
  {
    class: "box7",
    icon: Icon7,
    name: "MYCOTOXINS - LC/MS/MS",
    desc: "Analysis of all 5 state specified mycotoxins",
    // desc2: "ISO Accredited",
    link: "/services/mycotoxin",
  },
  {
    class: "box8",
    icon: Icon8,
    name: "Water Activity",
    desc: "",
    desc2: "",
    link: "/services/water-activity",
  },
]

export default function ServicesSection() {
  return (
    <section className="svc-section">
      <div id="services" />
      <Container>
        <h2
          className="my-0 sub-heading text-center"
          style={{ paddingTop: "100px" }}
        >
          Analytical Services
        </h2>
        <h2 className="mt-0 mb-5 text-center">
          We are CDPHE Certified and ISO Accredited
        </h2>
        <Row>
          {services.map((svc) => (
            <Col lg="3" className="my-3">
              <Link
                className="text-dark pe-none  text-center d-flex justify-content-center align-items-center"
                to={svc?.link}
                // style={{
                //   pointerEvents:
                //     svc?.link === "/services/potency" ? "none" : "auto",
                // }}
              >
                <div className={` ${svc.class} svc-box `}>
                  <img src={svc.icon} alt={svc.name} />
                  <h5 className="mt-3 mb-2">{svc.name}</h5>
                  <p className="mb-0">{svc.desc}</p>
                  <p style={{ fontSize: "13px" }}>{svc.desc2}</p>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}
